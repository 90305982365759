import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactFormComponent = ({ className }) => {
  // const [isValidated, setIsValidated] = useState(false)
  const [formState, setFormState] = useState({})

  const handleChange = e => {
    const f = {...formState};
    f[e.target.name] = e.target.value;
    setFormState(f);
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  return (
    <div className={className}>
      <form
        name="contact-form"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input tw="hidden" name="bot-field" />
        <div tw="sm:flex flex-wrap pb-6">
          <div tw="sm:w-1/2 sm:pr-12 pb-8 sm:pb-0">

            <label className="label" htmlFor={'name'}>
              Your Name
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'name'}
                onChange={handleChange}
                id={'name'}
                required={true}
              />
            </div>
          </div>
          <div tw="sm:w-1/2">
            <label className="label" htmlFor={'email'}>
              Email
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={handleChange}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div tw="pt-8 pb-8 sm:pb-0 w-full">
            <label className="label" htmlFor={'name'}>
              Company Name
            </label>
            <div className="control" tw="w-full">
              <input
                className="input"
                tw="w-full"
                type={'text'}
                name={'company_name'}
                onChange={handleChange}
                id={'company_name'}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'message'}>
            Message
          </label>
          <div className="control">
            <textarea
              className="textarea"
              name={'message'}
              onChange={handleChange}
              id={'message'}
              required={true}
            />
          </div>
        </div>
        <div tw="pt-5" className="field">
          <button tw="py-3 w-40 px-2 bg-techna-blue text-white rounded border-0" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  )
}

ContactFormComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

const ContactForm = styled(ContactFormComponent)`
  ${tw``}
  .label {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-bottom: 0.25rem;
    display: block;
    font-size: 0.85rem;
  }
  input, textarea {
    ${tw`rounded border border-solid w-full border-gray-400 py-3 px-4`}
  }
  textarea {
    height: 300px;
  }
  button {

  }
`

export default ContactForm
