import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import Content, { HTMLContent } from '../components/global/Content'
import ContactForm from '../components/contact/ContactForm'
import PageHeading from '../components/global/PageHeading'

export const ContactTemplate = ({
  title,
  content,
  className,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className={className}>
      {helmet || ''}
      <PageHeading title={title} />
      <Container tw="py-12">
        <Col tw="pb-8">
          <PageContent className="content" content={content} />
        </Col>
        <Col tw="lg:w-3/4">
          <ContactForm />
        </Col>
        <Col tw="hidden lg:flex lg:w-1/4">
          <p>
            <strong>Techna NDT</strong><br />
            6707 S. 216th St.<br />
            Kent, WA  98032A<br />
            <br />
            Tel: <a href="tel:2538722415">(253) 872-2415</a><br />
            Fax: <a href="fax:+12538722416">(253) 872-2416</a><br />
            <br />
            <a href="mailto:sales@technandt.com">sales@technandt.com</a>
          </p>
        </Col>
      </Container>
    </div>
  )
}

ContactTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  className: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
}

const ContactPageComponent = ({ data, className }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContactTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        className={className}
        helmet={
          <Helmet titleTemplate="%s - Techna NDT">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

ContactPageComponent.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const ContactPage = styled(ContactPageComponent)`
  ${tw``}
`

export default ContactPage

export const ContactQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
